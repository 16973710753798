import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// import { } from '@fortawesome/free-regular-svg-icons'

import {
  faUser, faLock, faSignOutAlt, faCog, faTrashAlt, faList, faChurch, faCross, faPhotoVideo, faPodcast
} from '@fortawesome/free-solid-svg-icons'

import {
  faGithub, faFacebook, faTwitter, faInstagram, faGoogle, faYoutube
} from '@fortawesome/free-brands-svg-icons'

library.add(
  faUser, faLock, faSignOutAlt, faCog, faTrashAlt, faList, faChurch, faCross, faPhotoVideo, faPodcast,
  faGithub, faFacebook, faTwitter, faInstagram, faGoogle, faYoutube
);

Vue.component('fa', FontAwesomeIcon);
