import axios from 'axios';
import { compile } from 'path-to-regexp';
import _ from 'lodash';
import $ from 'jquery';
import { objectToFormData } from 'object-to-formdata'

/**
 *
 * Basic usage:
 *
 ResourceApi.defaults = { base: '' };

 const UserApi = ResourceApi.create({
    url: '/child',
    parse: function (resp) { return resp; }
  });

 UserApi.all();
 UserApi.create({...});
 UserApi.fetch(1);
 UserApi.fetch({id: 1});
 UserApi.save({id: 1, ...});
 UserApi.destroy(1);
 UserApi.destroy({id: 1, ...});
 **/

class ResourceApi {
  constructor(instanceConfig) {
    this.defaults = instanceConfig;
  }
  create(options) {
    const Resource = {
      getUri: function (singular) {
        return this.base + this.url + (singular ? '/:id' : '');
      },

      /**
       * Build a URL by appending params to the end
       *
       * @param {string} url The base of the url (e.g., http://www.google.com)
       * @param {object} [params] The params to be appended
       * @returns {string} The formatted url
       */
      buildUrl: function (url, params) {
        let compiled_url = compile(url)(params);
        console.log(url, compiled_url);
        return compiled_url;
      },

      parse: function (resp) {
        return resp;
      },

      /**
       * Fetch a single model from the resource
       * @param params
       * @returns {Promise<any>}
       */
      fetch: function (params) {
        if (typeof params !== 'object') {
          params = { id: params };
        }

        return axios.get(this.buildUrl(this.getUri(true), params), {
          params: params,
          paramsSerializer: function (params) {
            return $.param(params);
          }
        });
      },

      /**
       * Fetch a collection of objects from the resource
       * @param params
       * @returns {Promise<any>}
       */
      all: function (params) {
        return new Promise((resolve, reject) => {
          axios.get(this.buildUrl(this.getUri(), params), {
            params: params,
            paramsSerializer: function (params) {
              return $.param(params);
            }
          })
            .then(this.parse)
            .then(resolve)
            .catch(err => {
              reject(err);
            });
        });
      },

      /**
       * Create a new model from the data object
       * @param form
       * @param params
       * @returns {Promise<any>}
       */
      create: function (form, params) {
        return new Promise((resolve, reject) => {
          form.submit('post', this.buildUrl(this.getUri(), params), {
            // Transform form data to FormData
            transformRequest: [function(data) {
              return objectToFormData(data, {indices: true});
            }]
          }).then(this.parse)
            .then(resolve)
            .catch(err => {
              reject(err);
            });
        });
      },

      /**
       * Update a model from the data object
       * @param form
       * @param params
       * @returns {Promise<any>}
       */
      update: function (form, params) {
        return new Promise((resolve, reject) => {
          form.submit('post', this.buildUrl(this.getUri(true), params), {
            // Transform form data to FormData
            transformRequest: [function(data) {
              data._method = 'put';
              return objectToFormData(data, {indices: true});
            }]
          }).then(this.parse)
            .then(resolve)
            .catch(err => {
              reject(err);
            });
        });
      }
    };
    const api = _.extend(Resource, this.defaults, options);

    // TODO: store the api to be able to fetch it later
    return api;
  }
}


// Create the default instance to be exported
const instance = new ResourceApi({ base: '/api/v1' });

// Expose ResourceApi class to allow class inheritance
instance.ResourceApi = ResourceApi;

export default instance;
