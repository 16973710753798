import Vue from 'vue'
import _ from 'lodash'
import * as types from '../mutation-types'
import ResourceApi from '../../plugins/vform-resource'

// state
export const state = {
  // Collection of all churches
  churches: {}, 

  locations: [],

  // Collection of most recent fetched sermons
  sermons: {},

  // Collection of most recent fetched speakers
  speakers: {},

  // Collection of most recent fetched series
  series: {},

  // Collection
  topics: [],
  users: [],
};

// getters
export const getters = {
  churches: state => _.sortBy(state.churches, 'name'),
  churchById: (state) => (id) => state.churches[id],

  series: state => state.series,
  seriesById: (state) => (id) => state.series[id],
  seriesByChurch: (state) => (church_id) => {
    return _.filter(state.series, {church_id: parseInt(church_id) });
  },

  sermons: state => state.sermons,

  speakers: state => state.speakers,
  speakersByChurch: (state) => (church_id) => {
    return _.filter(state.speakers, {church_id: parseInt(church_id) });
  },
  speakerById: (state) => (id) => state.speakers[id],
  
  topics: state => state.topics,
  topicsArray: state => _.values(state.topics),
  
  users: state => state.users,
};

// mutations
export const mutations = {
  [types.FETCH_CHURCHES_SUCCESS] (state, { churches }) {
    // TODO: Merge existing churches with new onws
    state.churches = _.keyBy(churches, 'id');
  },
  [types.FETCH_CHURCHES_FAILURE] (state) {
    state.churches = null;
  },
  [types.UPDATE_CHURCHES] (state, { church }) {
    Vue.set(state.churches, church.id, church);
  },
  
  [types.FETCH_SERMONS_SUCCESS] (state, { sermons }) {
    state.sermons = _.keyBy(sermons, 'id');
  },
  [types.FETCH_SERMONS_FAILURE] (state) {
    state.sermons = null;
  },
  [types.UPDATE_SERMONS] (state, { sermon }) {
    Vue.set(state.sermons, sermon.id, sermon);
  },

  [types.FETCH_SPEAKERS_SUCCESS] (state, { speakers }) {
    state.speakers = _.keyBy(speakers, 'id');
  },
  [types.FETCH_SPEAKERS_FAILURE] (state) {
    state.speakers = null;
  },
  [types.UPDATE_SPEAKERS] (state, { speaker }) {
    Vue.set(state.speakers, speaker.id, speaker);
  },

  [types.FETCH_SERIES_SUCCESS] (state, { series }) {
    state.series = _.keyBy(series, 'id');
  },
  [types.FETCH_SERIES_FAILURE] (state) {
    state.series = null;
  },
  [types.UPDATE_SERIES] (state, { series }) {
    Vue.set(state.series, series.id, series);
  },

  [types.FETCH_TOPICS_SUCCESS] (state, { topics }) {
    state.topics = _.keyBy(topics, 'id');
  },
  [types.FETCH_TOPICS_FAILURE] (state) {
    state.topics = null;
  },
  [types.UPDATE_TOPICS] (state, { topic }) {
    Vue.set(state.topics, topic.id, topic);
  },

  [types.FETCH_USERS_SUCCESS] (state, { users }) {
    state.users = _.keyBy(users, 'id');
  },
  [types.FETCH_USERS_FAILURE] (state) {
    state.users = null;
  },
  [types.UPDATE_USERS] (state, { user }) {
    Vue.set(state.users, user.id, user);
  },
};

let ChurchesApi = ResourceApi.create({
  'url': '/admin/churches',
});
let SermonsApi = ResourceApi.create({
  'url': '/admin/sermons',
});
let SpeakersApi = ResourceApi.create({
  'url': '/admin/speakers',
});
let SeriesApi = ResourceApi.create({
  'url': '/admin/series',
});
let UsersApi = ResourceApi.create({
  'url': '/admin/users',
});
let TopicsApi = ResourceApi.create({
  'url': '/topics',
})

// actions
export const actions = {
  /**
   * Fetch all churches, admin ONLY
   */
  fetchChurches ({ commit }, params) {
    return ChurchesApi.all(params).then(({data}) => {
      commit(types.FETCH_CHURCHES_SUCCESS, data);
      return data;
    });
  },

  /**
   * Replace the store with the church payload
   */
  updateChurch ({ commit }, { form, params }) {
    return ChurchesApi.update(form, params).then(({ data }) => {
      commit(types.UPDATE_CHURCHES, data);
    });    
  },

  /**
   * Fetch AllSermons, admin ONLY
   */
  fetchSermons ({ commit }, params) {
    return SermonsApi.all(params).then(({data}) => {
      commit(types.FETCH_SERMONS_SUCCESS, data);
      return data;
    });
  },

  /**
   * Fetch Sermon
   */
  fetchSermon ({ commit }, params) {
    return SermonsApi.fetch(params).then(({data}) => {
      commit(types.UPDATE_SERMONS, data);
      return data;
    });
  },

  /**
   * Replace the store with the sermon payload
   */
  updateSermons ({ commit }, { form, params }) {
    return SermonsApi.update(form, params).then(({ data }) => {
      commit(types.UPDATE_SERMONS, data);
      return data;
    });   
  },

  /**
   * Fetch speakers, admin ONLY
   */
  fetchSpeakers ({ commit }, params) {
    return SpeakersApi.all(params).then(({data}) => {
      commit(types.FETCH_SPEAKERS_SUCCESS, data);
      return data;
    });
  },

  /**
   * Replace the store with the speaker payload
   */
  updateSpeakers ({ commit }, { form, params }) {
    return SpeakersApi.update(form, params).then(({ data }) => {
      commit(types.UPDATE_SPEAKERS, data);
    });  
  },

  /**
   * Fetch all series, admin ONLY
   */
  fetchSeries ({ commit }, params) {
    return SeriesApi.all(params).then(({data}) => {
      commit(types.FETCH_SERIES_SUCCESS, data);
      return data;
    });
  },

  /**
   * Replace the store with the speaker payload
   */
  updateSeries ({ commit }, { form, params }) {
    return SeriesApi.update(form, params).then(({ data }) => {
      commit(types.UPDATE_SERIES, data);
    });    
  },

  /**
   * Fetch a list of all users
   */
  fetchUsers ({ commit }, params) {
    return UsersApi.all(params).then(({data}) => {
      commit(types.FETCH_USERS_SUCCESS, data);
      return data;
    });
  },
  
  /**
   * Replace store with user's payload
   */
  updateUsers ({ commit }, { form, params }) {
    return UsersApi.update(form, params).then(({ data }) => {
      commit(types.UPDATE_USERS, data);
    });  
  },

  /**
   * Fetch a list of all topics
   */
   fetchTopics ({ commit }, params) {
    return TopicsApi.all(params).then(({data}) => {
      commit(types.FETCH_TOPICS_SUCCESS, data);
      return data;
    });
  },
};
