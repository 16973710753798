<template>
  <div class="main-layout">
    <navbar is-sidebar />

    <div id="sidebarMenu" class="sidebar sidebar-fixed sidebar-components">
      <div class="sidebar-header">
        <router-link :to="{ name: 'home' }" class="df-logo">
          all
          <span>sermons</span>
        </router-link>
        <a href id="sidebarMenuClose" v-on:click.prevent="closeMenu()"><i data-feather="x"></i></a>
      </div><!-- sidebar-header -->
      <div class="sidebar-body">
        <ul class="sidebar-nav">
          <li class="nav-item show">
            <a class="nav-link with-sub">Settings</a>
            <nav class="nav">
              <router-link :to="{ name: 'accounts.profile' }" class="" active-class="active">
                {{ $t('profile') }}
              </router-link>
              <router-link :to="{ name: 'accounts.password' }" class="" active-class="active">
                {{ $t('password') }}
              </router-link>
            </nav>
          </li>
          <li class="nav-item show">
            <a class="nav-link with-sub">Church</a>
            <nav class="nav">
              <router-link :to="{ name: 'accounts.church.register' }" class="" active-class="active" v-if="!church">
                {{ $t('register') }}
              </router-link>
              <router-link :to="{ name: 'accounts.church' }" class="" active-class="active" v-if="church">
                {{ church.name }}
              </router-link>
              <router-link :to="{ name: 'accounts.series.index' }" class="" active-class="active" v-if="church">
                {{ $t('series.default') }}
              </router-link>
              <router-link :to="{ name: 'accounts.sermons.index' }" class="" active-class="active" v-if="church">
                {{ $t('sermons.default') }}
              </router-link>
              <router-link :to="{ name: 'accounts.speakers.index' }" class="" active-class="active" v-if="church">
                {{ $t('speakers.default') }}
              </router-link>
            </nav>
          </li>

          <li class="nav-item show" v-if="is_admin">
            <a class="nav-link with-sub">Admin</a>
            <nav class="nav">
              <router-link :to="{ name: 'admin.users.index' }" class="" active-class="active">
                {{ $t('users.default') }}
              </router-link>
              <router-link :to="{ name: 'admin.churches.index' }" class="" active-class="active">
                {{ $t('churches.default') }}
              </router-link>
              <router-link :to="{ name: 'admin.sermons.index', query: {church_id: $route.params.church_id || $route.query.church_id} }" class="" active-class="active">
                {{ $t('sermons.default') }}
              </router-link>
              <router-link :to="{ name: 'admin.speakers.index', query: {church_id: $route.params.church_id || $route.query.church_id} }" class="" active-class="active">
                {{ $t('speakers.default') }}
              </router-link>
              <router-link :to="{ name: 'admin.series.index', query: {church_id: $route.params.church_id || $route.query.church_id} }" class="" active-class="active">
                {{ $t('series.default') }}
              </router-link>
            </nav>
          </li>
        </ul><!-- .sidebar-nav -->
          
      </div><!-- sidebar-body -->
    </div><!-- sidebar -->

    <div class="content mg-lg-l-240 mg-lg-t-60">
      <div class="container-fluid">
        <child />

        <footer class="content-footer">
          <div>
            <span>© 2022 allsermons v1.1.2. </span>
            <span>Made by <a href="https://bit9labs.com">Bit9Labs LLC</a></span>
          </div>
          <div>
            <nav class="nav">
              <router-link :to="{name: 'terms'}" class="nav-link">Terms Of Use</router-link>
              <router-link :to="{name: 'privacy'}" class="nav-link">Privacy Policy</router-link>
            </nav>
          </div>
        </footer><!-- .content-footer -->
      </div><!-- .container -->
    </div><!-- .content -->     
  </div><!-- .main-layout -->
</template>

<script>
import Navbar from '~/components/Navbar'
import { mapGetters } from 'vuex'

export default {
  name: 'AccountsLayout',

  components: {
    Navbar
  },
  computed: mapGetters({
    church: 'account/church',
    is_admin: 'auth/admin'
  }),
  watch: {
    // call again the method if the route changes
    '$route': 'closeMenu'
  },
  beforeDestroy () {
    this.closeMenu();
  },
  methods: {
    closeMenu() {
      let body = document.body;
      body.classList.remove("sidebar-show");
    },
  }
}
</script>
