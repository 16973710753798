<template>
  <div class="main-layout">
    <navbar />

    <div class="content content-fixed">
      <child />

      <footer class="content-footer">
      <div>
        <span>© 2022 allsermons v1.1.2. </span>
        <span>Made by <a href="https://bit9labs.com">Bit9Labs LLC</a></span>
      </div>
      <div>
        <nav class="nav">
          <router-link :to="{name: 'terms'}" class="nav-link">Terms Of Use</router-link>
          <router-link :to="{name: 'privacy'}" class="nav-link">Privacy Policy</router-link>
        </nav>
      </div>
    </footer>
    </div>

     
  </div>
</template>

<script>
import Navbar from '~/components/Navbar'

export default {
  name: 'MainLayout',

  components: {
    Navbar
  }
}
</script>
