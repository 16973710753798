<template>
  <div class="main-layout">
    <navbar />

    <div class="content content-fixed content-auth">
      <div class="container">
        <child />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '~/components/Navbar'

export default {
  name: 'AuthLayout',
  components: {
    Navbar
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/dashforge.auth.css';
</style>