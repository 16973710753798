import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import * as types from '../mutation-types'
import $ from 'jquery';

// state
export const state = {
  churches: [],
  sermons: [],
  topics: [],
};

// getters
export const getters = {
  church: (state) => (id) => state.churches[id],
  churches: state => state.churches,
  sermon: (state) => (id) => state.sermons[id],
  sermons: state => state.sermons,
  topic: (state) => (id) => state.topics[id],
  topics: state => state.topics,
  topicsArray: state => _.values(state.topics),
};

// mutations
export const mutations = {
  [types.FETCH_CHURCH_SUCCESS] (state, { church }) {
    Vue.set(state.churches, church.id, church);
  },

  [types.FETCH_CHURCHES_SUCCESS] (state, { churches }) {
    state.churches = churches;
  },

  [types.FETCH_CHURCHES_FAILURE] (state) {
    state.churches = null;
  },

  [types.FETCH_SERMON_SUCCESS] (state, { sermon }) {
    //state.sermons[sermon.id] = sermon;
    Vue.set(state.sermons, sermon.id, sermon);
  },

  [types.FETCH_SERMONS_SUCCESS] (state, { sermons }) {
    state.sermons = sermons;
  },

  [types.FETCH_SERMONS_FAILURE] (state) {
    state.sermons = null;
  },

  [types.FETCH_TOPICS_SUCCESS] (state, { topics }) {
    state.topics = topics;
  },

  [types.FETCH_TOPICS_FAILURE] (state) {
    state.topics = null;
  },

};

// actions
export const actions = {
  async fetchChurches ({ commit }) {
    try {
      const { data } = await axios.get('/api/v1/churches');

      let churches = _.keyBy(data.churches, 'id');

      commit(types.FETCH_CHURCHES_SUCCESS, { churches: churches });
    } catch (e) {
      commit(types.FETCH_CHURCHES_FAILURE);
    }
  },

  fetchChurch ({ commit }, params) {
    return axios.get('/api/v1/churches/' + params.id)
      .then(({ data }) => {
        commit(types.FETCH_CHURCH_SUCCESS, { church: data.church });
        return data.church;
      })
      .catch(() => {
        commit(types.FETCH_CHURCH_FAILURE);
      });
  },

  fetchSermons ({commit}, params ) {
    return axios.get('/api/v1/sermons', { 
        params: params,
        paramsSerializer: function (params) {
          return $.param(params);
        }
      }).then(({data}) => {
        let sermons = _.keyBy(data.sermons, 'id');
        commit(types.FETCH_SERMONS_SUCCESS, { sermons: sermons });
        return data;
      }).catch(() => {
        commit(types.FETCH_SERMONS_FAILURE);
      })
  },

  fetchSermon ({ commit }, params) {
    return axios.get('/api/v1/sermons/' + params.id)
      .then(({data}) => {
        commit(types.FETCH_SERMON_SUCCESS, { sermon: data.sermon });
        
        return data.sermon;
      })
      .catch(() => {
        commit(types.FETCH_SERMON_FAILURE);
      });
  },

  async fetchTopics ({commit}, params ) {
    try {
      const { data } = await axios.get('/api/v1/topics', { 
        params: params,
        paramsSerializer: function (params) {
          return $.param(params);
        }
      });

      let topics = _.keyBy(data.topics, 'id');

      commit(types.FETCH_TOPICS_SUCCESS, { topics: topics });
    } catch (e) {
      commit(types.FETCH_TOPICS_FAILURE);
    }
  }
};
