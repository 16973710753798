<template>
  <header class="navbar navbar-header navbar-header-fixed">
    <a href id="mainMenuOpen" class="burger-menu" v-on:click.prevent="openMenu()">
      <i data-feather="menu"></i>
    </a>
    <div class="navbar-brand">
      <router-link :to="{ name: 'home' }" class="df-logo">
        all
        <span>sermons</span>
      </router-link>
    </div>
    <!-- navbar-brand -->

    <div id="navbarMenu" class="navbar-menu-wrapper">
      <div class="navbar-menu-header">
        <router-link :to="{ name: 'home' }" class="df-logo">
          all
          <span>sermons</span>
        </router-link>
        <a id="mainMenuClose" href v-on:click.prevent="closeMenu()">
          <i data-feather="x"></i>
        </a>
      </div>
      <!-- navbar-menu-header -->

      <ul class="nav navbar-menu justify-content-center">
        <li class="nav-label pd-l-20 pd-lg-l-25 d-lg-none">Main Navigation</li>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ name:'home' }">
            <fa class="d-lg-none" :icon="['fa', 'photo-video']" />
            {{ 'Sermons' }}
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ name:'churches.index' }">
            <fa class="d-lg-none" :icon="['fa', 'church']" />
            {{ 'Churches' }}
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ name:'topics.index' }">
            <fa class="d-lg-none" :icon="['fa', 'list']" />
            {{ 'Topics' }}
          </router-link>
        </li>
        
      </ul>

      <ul class="nav navbar-menu pos-absolute wd-100p b-0 d-lg-none">
        <li class="nav-item">
          <div class="dropdown-divider"></div>
        </li>
        <li class="nav-item" v-if="!user">
          <router-link :to="{ name: 'login' }" class="nav-link" v-if="!user">
            <i data-feather="log-in" style="margin-right:1rem"></i>&nbsp;{{ 'Login' }}
          </router-link>
        </li>
        <li class="nav-item" v-if="!user">
          <router-link :to="{ name: 'register' }" class="nav-link" v-if="!user">
            <i data-feather="file-text" style="margin-right:1rem"></i><span>{{ 'Register' }}</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="user">
          <router-link class="nav-link" :to="{ name:'accounts.profile' }">
            <i data-feather="bar-chart" style="margin-right:1rem"></i>{{ user.name }}
          </router-link>
        </li>
      </ul>
    </div>
    <!-- navbar-menu-wrapper -->

    <div class="navbar-right">
      <router-link :to="{ name: 'login' }" class="btn btn-login" v-if="!user">
        <i data-feather="log-in" style="margin-right:.2rem"></i>&nbsp;{{ 'Login' }}
      </router-link>
      <router-link :to="{ name: 'register' }" class="btn btn-register" v-if="!user">
        <span>{{ 'Register' }}</span>
      </router-link>
      <div class="dropdown dropdown-profile" v-if="user">
        <a href="#" class="dropdown-link" data-toggle="dropdown" data-display="static" >
          <span>{{ user.name }}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-right tx-13">
          <!--<router-link class="dropdown-item" :to="{ name:'welcome' }"><i data-feather="bar-chart"></i> {{ 'Dashboard' }}</router-link>-->
          <router-link class="dropdown-item" :to="{ name:'accounts.profile' }">
            <i data-feather="bar-chart"></i>
            {{ 'Profile' }}
          </router-link>
          <a class="dropdown-item" href="#" @click="logout">
            <i data-feather="log-out"></i>
            {{ 'Logout' }}
          </a>
          <a class="dropdown-item" href="#" @click="stopImpersonate" v-show="impersonate">
            <span class="tx-danger">
              <i data-feather="x-octagon"></i>
              {{ 'Stop Impersonating' }}
            </span>
          </a>
        </div>
      </div>
    </div>
    <!-- navbar-right -->
  </header>
  <!-- navbar -->
</template>

<script>
import { mapGetters } from "vuex";
import feather from "feather-icons";

export default {
  props: {
    isSidebar: Boolean,
  },
  computed: mapGetters({
    user: "auth/user",
    impersonate: "auth/impersonate"
  }),
  mounted: function() {
    this.$nextTick(function() {
      // Code that will run only after the
      // entire view has been rendered
      feather.replace();

      let backdrop = document.querySelectorAll(".backdrop");
      
      if (!backdrop.length) {
        backdrop = document.createElement("div");
        backdrop.classList.add("backdrop");
        document.body.appendChild(backdrop);
        backdrop.onclick = function() {
          let body = document.body;
          body.classList.remove("navbar-nav-show");
        };
      }
    });
  },
  methods: {
    openMenu() {
      let body = document.body;
      if (this.isSidebar) {
        body.classList.add("sidebar-show");
      } else {
        body.classList.add("navbar-nav-show");
      }
    },
    closeMenu() {
      let body = document.body;
      if (this.isSidebar) {
        body.classList.remove("sidebar-show");
      } else {
        body.classList.remove("navbar-nav-show");
      }
    },
    async logout() {
      // Log out the user.
      await this.$store.dispatch("auth/logout");
      // Redirect to login.
      this.$router.push({ name: "login" });
    },
    async stopImpersonate() {
      // Log out the user.
      await this.$store.dispatch('auth/stopImpersonate');
      await this.$store.dispatch('auth/fetchUser');
      await this.$store.dispatch('account/fetchChurch');
      this.$router.push({ name: "accounts.profile" });
    }
  }
};
</script>
