import axios from 'axios'
import Cookies from 'js-cookie'
import * as types from '../mutation-types'

// state
export const state = {
  user: null,
  token: Cookies.get('token')
};

// getters
export const getters = {
  user: state => state.user,
  token: state => state.token,
  check: state => state.user !== null,
  admin: state => state.user && state.user.role === "admin",
  impersonate: state => state.token && state.token !== Cookies.get('token')
};

// mutations
export const mutations = {
  [types.SAVE_TOKEN] (state, { token, remember }) {
    state.token = token;
    Cookies.set('token', token, { expires: remember ? 365 : null });
  },

  [types.IMPERSONATE_TOKEN] (state, { token }) {
    state.token = token;
  },

  [types.FETCH_USER_SUCCESS] (state, { user }) {
    state.user = user;
  },

  [types.FETCH_USER_FAILURE] (state) {
    state.token = null;
    Cookies.remove('token');
  },

  [types.LOGOUT] (state) {
    state.user = null;
    state.token = null;

    Cookies.remove('token');
  },

  [types.UPDATE_USER] (state, { user }) {
    state.user = user;
  }
};

// actions
export const actions = {
  saveToken ({ commit }, payload) {
    commit(types.SAVE_TOKEN, payload);
  },

  impersonate ({ commit }, payload) {
    commit(types.IMPERSONATE_TOKEN, payload);
  },

  stopImpersonate ({ commit }) {
    let payload = { token: Cookies.get('token') };
    commit(types.IMPERSONATE_TOKEN, payload);
  },

  async fetchUser ({ commit }) {
    try {
      const { data } = await axios.get('/api/v1/user');

      commit(types.FETCH_USER_SUCCESS, { user: data.user });
    } catch (e) {
      commit(types.FETCH_USER_FAILURE);
    }
  },

  updateUser ({ commit }, payload) {
    commit(types.UPDATE_USER, payload);
  },

  async logout ({ commit }) {
    try {
      await axios.post('/api/v1/logout');
    } catch (e) { 
      /* do nothing */
    }

    commit(types.LOGOUT);
  },

  async fetchOauthUrl (ctx, { provider }) {
    const { data } = await axios.post(`/api/v1/oauth/${provider}`);

    return data.url;
  }
};
