// auth.js
export const LOGOUT = 'LOGOUT';
export const SAVE_TOKEN = 'SAVE_TOKEN';
export const IMPERSONATE_TOKEN = 'IMPERSONATE_TOKEN';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USERS = 'UPDATE_USERS';

export const FETCH_CHURCH = 'FETCH_CHURCH';
export const FETCH_CHURCH_SUCCESS = 'FETCH_CHURCH_SUCCESS';
export const FETCH_CHURCH_FAILURE = 'FETCH_CHURCH_FAILURE';
export const FETCH_CHURCHES = 'FETCH_CHURCHES';
export const FETCH_CHURCHES_SUCCESS = 'FETCH_CHURCHES_SUCCESS';
export const FETCH_CHURCHES_FAILURE = 'FETCH_CHURCHES_FAILURE';
export const UPDATE_CHURCH = 'UPDATE_CHURCH';
export const UPDATE_CHURCHES = 'UPDATE_CHURCHES';

export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_FAILURE = 'FETCH_LOCATIONS_FAILURE';
export const UPDATE_LOCATIONS = 'UPDATE_LOCATIONS';

export const FETCH_SERIES = 'FETCH_SERIES';
export const FETCH_SERIES_SUCCESS = 'FETCH_SERIES_SUCCESS';
export const FETCH_SERIES_FAILURE = 'FETCH_SERIES_FAILURE';
export const UPDATE_SERIES = 'UPDATE_SERIES';

export const FETCH_SERMON = 'FETCH_SERMON';
export const FETCH_SERMON_SUCCESS = 'FETCH_SERMON_SUCCESS';
export const FETCH_SERMON_FAILURE = 'FETCH_SERMON_FAILURE';
export const FETCH_SERMONS = 'FETCH_SERMONS';
export const FETCH_SERMONS_SUCCESS = 'FETCH_SERMONS_SUCCESS';
export const FETCH_SERMONS_FAILURE = 'FETCH_SERMONS_FAILURE';
export const UPDATE_SERMONS = 'UPDATE_SERMONS';

export const FETCH_SPEAKER = 'FETCH_SPEAKER';
export const FETCH_SPEAKER_SUCCESS = 'FETCH_SPEAKER_SUCCESS';
export const FETCH_SPEAKER_FAILURE = 'FETCH_SPEAKER_FAILURE';
export const FETCH_SPEAKERS = 'FETCH_SPEAKERS';
export const FETCH_SPEAKERS_SUCCESS = 'FETCH_SPEAKERS_SUCCESS';
export const FETCH_SPEAKERS_FAILURE = 'FETCH_SPEAKERS_FAILURE';
export const UPDATE_SPEAKERS = 'UPDATE_SPEAKERS';

export const FETCH_TOPICS = 'FETCH_TOPICS';
export const FETCH_TOPICS_SUCCESS = 'FETCH_TOPICS_SUCCESS';
export const FETCH_TOPICS_FAILURE = 'FETCH_TOPICS_FAILURE';
export const UPDATE_TOPICS = 'UPDATE_TOPICS';

// lang.js
export const SET_LOCALE = 'SET_LOCALE';
