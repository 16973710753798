import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import * as types from '../mutation-types'
import ResourceApi from '../../plugins/vform-resource'
import $ from 'jquery';

// state
export const state = {
  church: null,
  locations: [],
  series: [],
  sermons: [],
  speakers: [],
};

// getters
export const getters = {
  church: state => state.church,
  check: state => state.church !==null,

  // Series getters
  seriesById: (state) => (id) => _.get(state.series, id),
  series: state => state.series,
  seriesArray: state => _.values(state.series),
  
  // Sermon getters
  sermonById: (state) => (id) => _.get(state.sermons, id),
  sermons: state => state.sermons,
  sermonBySource: (state) => (source_id) => {
    return _.head(_.filter(state.sermons, {source_id: source_id}));
  },
  hasSource: (state) => (source_id) => {
    return _.map(state.sermons, 'source_id').includes(source_id);
  },

  // Speaker getters
  speakerById: (state) => (id) => _.get(state.speakers, id),
  speakers: state => state.speakers,
  speakersArray: state => _.values(state.speakers)
};

// mutations
export const mutations = {
  [types.FETCH_CHURCH_SUCCESS] (state, { church }) {
    state.church = church;
  },
  [types.FETCH_CHURCH_FAILURE] (state) {
    state.church = null;
  },
  [types.UPDATE_CHURCH] (state, { church }) {
    state.church = church;
  },
  
  [types.FETCH_SERMONS_SUCCESS] (state, { sermons }) {
    state.sermons = _.keyBy(sermons, 'id');
  },
  [types.FETCH_SERMONS_FAILURE] (state) {
    state.sermons = null;
  },
  [types.UPDATE_SERMONS] (state, { sermon }) {
    Vue.set(state.sermons, sermon.id, sermon);
  },

  [types.FETCH_SERIES_SUCCESS] (state, { series }) {
    state.series = series;
  },
  [types.FETCH_SERIES_FAILURE] (state) {
    state.series = null;
  },
  [types.UPDATE_SERIES] (state, { series }) {
    Vue.set(state.series, series.id, series);
  },

  [types.FETCH_SPEAKERS_SUCCESS] (state, { speakers }) {
    state.speakers = speakers;
  },
  [types.FETCH_SPEAKERS_FAILURE] (state) {
    state.speakers = null;
  },
  [types.UPDATE_SPEAKERS] (state, { speaker }) {
    Vue.set(state.speakers, speaker.id, speaker);
  },
};

// let ChurchesApi = ResourceApi.create({
//   'url': '/admin/churches',
// });
let SermonsApi = ResourceApi.create({
  'url': '/user/me/sermons',
});
// let SpeakersApi = ResourceApi.create({
//   'url': '/admin/speakers',
// });
// let SeriesApi = ResourceApi.create({
//   'url': '/admin/series',
// });
// let UsersApi = ResourceApi.create({
//   'url': '/admin/users',
// });
// let TopicsApi = ResourceApi.create({
//   'url': '/topics',
// })

// actions
export const actions = {
  /**
   * Fetch the church that this user belongs to
   */
  async fetchChurch ({ commit }) {
    try {
      const { data } = await axios.get('/api/v1/user/me/church');

      commit(types.FETCH_CHURCH_SUCCESS, { church: data.church });
    } catch (e) {
      commit(types.FETCH_CHURCH_FAILURE);
    }
  },

  /**
   * Replace the store with the church payload
   */
  updateChurch ({ commit }, payload) {
    commit(types.UPDATE_CHURCH, payload);
  },

  /**
   * Fetch all the sermon series from this user's church
   */
  async fetchSeries ({ commit }, params) {
    return axios.get('/api/v1/user/me/series', {
      params: params,
      paramsSerializer: function (params) {
        return $.param(params);
      }
    })
    .then(({data}) => {
      let series = _.keyBy(data.series, 'id');
      commit(types.FETCH_SERIES_SUCCESS, { series: series });
      return data;
    })
    .catch(() => {
      commit(types.FETCH_SERIES_FAILURE);
    });
  },

  /**
   * Replace the store with the series payload
   */
  updateSeries ({ commit }, payload) {
    commit(types.UPDATE_SERIES, payload);
  },

  /**
   * Fetch all the sermons from this user's church
   */
  fetchSermons ({ commit }, params) {
    return SermonsApi.all(params).then(({data}) => {
      commit(types.FETCH_SERMONS_SUCCESS, data);
      return data;
    }).catch(() => {
      commit(types.FETCH_SERMONS_FAILURE);
    });
  },

  /**
   * Fetch Sermon
   */
  fetchSermon ({ commit }, params) {
    return SermonsApi.fetch(params).then(({data}) => {
      commit(types.UPDATE_SERMONS, data);
      return data;
    });
  },

  /**
   * Create a new sermon
   */
  createSermon ({ commit }, { form, params }) {
    return SermonsApi.create(form, params).then(({ data }) => {
      commit(types.UPDATE_SERMONS, data);
      return data;
    });
  },

  /**
   * Update an existing sermon
   */
   updateSermon ({ commit }, { form, params }) {
    return SermonsApi.update(form, params).then(({ data }) => {
      commit(types.UPDATE_SERMONS, data);
      return data;
    });
  },

  /**
   * Replace the store with the sermon payload
   */
  updateSermons ({ commit }, payload) {
    commit(types.UPDATE_SERMONS, payload);
  },

  /**
   * Fetch all speakers from this user's church
   */
  async fetchSpeakers ({ commit }, params) {
    return axios.get('/api/v1/user/me/speakers', {
      params: params,
      paramsSerializer: function (params) {
        return $.param(params);
      }
    })
    .then(({data}) => {
      let speakers = _.keyBy(data.speakers, 'id');

      commit(types.FETCH_SPEAKERS_SUCCESS, { speakers: speakers });

      return data;
    })
    .catch(() => {
      commit(types.FETCH_SPEAKERS_FAILURE);
    });
  },

  /**
   * Replace the store with this speaker's payload
   */
  updateSpeakers ({ commit }, payload) {
    commit(types.UPDATE_SPEAKERS, payload);
  },
};
