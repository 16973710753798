function page (path) {
  return () => import(/* webpackChunkName: '' */ `~/pages/${path}`).then(m => m.default || m)
}

export default [
  // Front End
  { path: '/', name: 'home', component: page('home.vue') },
  { path: '/s/:id', name: 'sermons.show', component: page('sermons/show.vue')}, // Watch a single sermon
  { path: '/c', name: 'churches.index', component: page('churches/index.vue')}, // List of all churches
  { path: '/c/map', name: 'churches.map', component: page('churches/map.vue')}, // List of all churches
  { path: '/c/:id/church/:name', name: 'churches.show', component: page('churches/show.vue')}, // View a single church
  { path: '/t', name: 'topics.index', component: page('topics/index.vue')}, // List of all topics
  { path: '/t/:id/topic/:text', name: 'topics.show', component: page('topics/show.vue')}, // View videos of a single topic
  { path: '/search', name: 'search.results', component: page('invite.vue')}, // View videos of related to a single search

  // Legal
  { path: '/terms-of-use', name: 'terms', component: page('terms.vue')}, 
  { path: '/privacy-policy', name: 'privacy', component: page('privacy.vue')}, 

  // Auth
  { path: '/accounts/login', name: 'login', component: page('auth/login.vue') },
  { path: '/accounts/register', name: 'register', component: page('auth/register.vue') },
  { path: '/accounts/password/reset', name: 'password.request', component: page('auth/password/email.vue') },
  { path: '/accounts/password/reset/:token', name: 'password.reset', component: page('auth/password/reset.vue') },
  // { path: '/email/verify/:id', name: 'verification.verify', component: page('auth/verification/verify.vue') },
  // { path: '/email/resend', name: 'verification.resend', component: page('auth/verification/resend.vue') },

  // Logged In
  //{ path: '/home', name: 'home', component: page('home.vue') },
  { 
    path: '/accounts',
    component: page('accounts/index.vue'),
    children: [
      { path: '', redirect: { name: 'accounts.profile' } },
      { path: 'profile', name: 'accounts.profile', component: page('accounts/profile.vue') },
      { path: 'password', name: 'accounts.password', component: page('accounts/password.vue') },
      
      { path: 'church/edit', name: 'accounts.church', component: page('accounts/church/edit.vue') },

      // Sermon Series
      { path: 'series', name: 'accounts.series.index', component: page('accounts/series/index.vue') },
      { path: 'series/create', name: 'accounts.series.create', component: page('accounts/series/create.vue') },
      { path: 'series/edit/series/:id', name: 'accounts.series.edit', component: page('accounts/series/edit.vue') },

      // Sermons
      { path: 'sermons', name: 'accounts.sermons.index', component: page('accounts/sermons/index.vue') },
      { path: 'sermons/create', name: 'accounts.sermons.create', component: page('accounts/sermons/create.vue') },
      { path: 'sermons/edit/sermon/:id', name: 'accounts.sermons.edit', component: page('accounts/sermons/edit.vue') },
      { path: 'sermons/import/youtube', name: 'accounts.sermons.import.youtube', component: page('accounts/sermons/youtube.vue') },

      // Speakers
      { path: 'speakers', name: 'accounts.speakers.index', component: page('accounts/speakers/index.vue') },
      { path: 'speakers/create', name: 'accounts.speakers.create', component: page('accounts/speakers/create.vue') },
      { path: 'speakers/edit/speaker/:id', name: 'accounts.speakers.edit', component: page('accounts/speakers/edit.vue') },
      
      // Register a new church
      { path: 'church/register', name: 'accounts.church.register', component: page('accounts/church/create.vue') },
      {
        path: 'invite',
        component: page('accounts/invite/index.vue'),
        children: [
          {path: '', name: 'accounts.church.invite', component: page('accounts/invite/invite.vue')},
          {path: ':code/church', name: 'accounts.church.create', component: page('accounts/invite/create.vue')}
        ]
      }
    ]},
    { 
      path: '/admin',
        component: page('accounts/index.vue'),
        children: [
          { path: 'users', name: 'admin.users.index', component: page('admin/users/index.vue') },
          { path: 'churches', name: 'admin.churches.index', component: page('admin/churches/index.vue') },
          { path: 'churches/create', name: 'admin.churches.create', component: page('admin/churches/create.vue') },
          { path: 'churches/edit/church/:church_id', name: 'admin.churches.edit', component: page('admin/churches/edit.vue') },
          { path: 'sermons', name: 'admin.sermons.index', component: page('admin/sermons/index.vue') },
          { path: 'sermons/create', name: 'admin.sermons.create', component: page('admin/sermons/create.vue') },
          { path: 'sermons/edit/sermon/:id', name: 'admin.sermons.edit', component: page('admin/sermons/edit.vue') },
          { path: 'speakers', name: 'admin.speakers.index', component: page('admin/speakers/index.vue') },
          { path: 'speakers/create', name: 'admin.speakers.create', component: page('admin/speakers/create.vue') },
          { path: 'speakers/edit/speaker/:id', name: 'admin.speakers.edit', component: page('admin/speakers/edit.vue') },
          { path: 'series', name: 'admin.series.index', component: page('admin/series/index.vue') },
          { path: 'series/create', name: 'admin.series.create', component: page('admin/series/create.vue') },
          { path: 'series/edit/series/:id', name: 'admin.series.edit', component: page('admin/series/edit.vue') },
        ]
    },

  { path: '*', component: page('errors/404.vue') }
]