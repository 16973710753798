<template>
  <div class="card">
    <div v-if="title || link" class="card-header">
      <span v-if="title">{{ title }}</span>
      <router-link v-if="link" :to="link.to" class="float-right">{{ link.text }}</router-link>
    </div>

    <div class="card-body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',

  props: {
    title: { type: String, default: null },
    link: { type: Object, default: null }
  }
}
</script>
